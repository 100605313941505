@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

html {
  min-height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,36,18,1) 63%, rgba(0,42,22,1) 100%) no-repeat;
  text-transform: uppercase;
  color: #33cc33;
}

* {
  font-family: 'Titillium Web';
}

p {
  text-transform: none;
}